import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { openAlert, openSnackbar } from "lib/service-redux-alerts";
import * as serviceAPI from "lib/service-api";
import COMMON_CONSTS from "../../constant/CommonConsts";

// const DEFAULT_POPUP_TITLE = "[파트너센터 안내]";
const DEFAULT_POPUP_TITLE = "파트너센터 안내";

/**
 * SignIn의 Module Component 입니다.<p/>
 *
 * 해당 Component는 서버 연동 및 공통 기능을 제공 합니다.
 *
 * @author Taesung Park <pts@pineone.com>
 * @name SignInContainer
 * @class
 * @component
 */
class SignInContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleOnRequestAjax = this.handleOnRequestAjax.bind(this);
    this.handleOpenPopup = this.handleOpenPopup.bind(this);
    this.handleOpenToast = this.handleOpenToast.bind(this);
    this.handleClickPopup = this.handleClickPopup.bind(this);
  }

  handleOnRequestAjax(type, params) {
    switch (type) {
      case "createDuplicationPhonenumberUsers":
        if (!!params) {
          const phoneNumber = params.phoneNumber || "";

          // [서버연동] 박태성C - 휴대폰 번호 중복 확인. POST /users/phonenumber/duplication
          return serviceAPI.createDuplicationPhonenumberUsers({
            phone_number: phoneNumber,
          });
        }
        break;
      case "createSendAuthcode":
        if (!!params) {
          const { userId, userName, phoneNumber, type } = params;

          // [서버연동] 박태성C - 인증번호 발송. POST /authcode/send
          return serviceAPI.createSendAuthcode({
            user_id: userId.trim() || "",
            user_name: userName.trim() || "",
            phone_number: phoneNumber.trim() || "",
            type: type || "",
          });
        }
        break;
      case "createVerifyAuthcode":
        if (!!params) {
          const phoneNumber = params.phoneNumber || "";
          const smsAuthCode = params.smsAuthCode || "";
          const smsAuthKey = params.smsAuthKey || "";

          // [서버연동] 박태성C - 인증번호 확인. POST /authcode/verify
          return serviceAPI.createVerifyAuthcode({
            phone_number: phoneNumber,
            sms_auth_code: smsAuthCode,
            sms_auth_key: smsAuthKey,
          });
        }
        break;
      case "createForgetIdUsers":
        if (!!params) {
          const {
            userName,
            email,
            phoneNumber,
            smsAuthCode,
            smsAuthKey,
          } = params;

          // [서버연동] 박태성C - 아이디 찾기. POST /users/id/forget
          return serviceAPI.createForgetIdUsers({
            email: email || null,
            phone_number: phoneNumber || null,
            sms_auth_code: smsAuthCode || null,
            sms_auth_key: smsAuthKey || null,
            user_name: userName,
            businessType : COMMON_CONSTS.SERVER_BUSINESS_TYPE.HOTEL
          });
        }
        break;
      case "createForgetPasswordUsers":
        if (!!params) {
          const {
            userId,
            userName,
            email,
            phoneNumber,
            smsAuthCode,
            smsAuthKey,
          } = params;

          // [서버연동] 박태성C - 비밀번호찾기. POST /users/password/forget
          return serviceAPI.createForgetPasswordUsers({
            email: email || null,
            phone_number: phoneNumber || null,
            sms_auth_code: smsAuthCode || null,
            sms_auth_key: smsAuthKey || null,
            user_id: !!userId ? userId.toLowerCase() : userId,
            user_name: userName,
            businessType : COMMON_CONSTS.SERVER_BUSINESS_TYPE.HOTEL
          });
        }
        break;
      default:
        break;
    }

    console.error(type, "\n", params);
    return Promise.reject(
      new Error("Undefined Command or Params value is none.")
    );
  }

  handleOpenToast(message) {
    this.props.openSnackbar({
      message: message,
    });
  }

  handleOpenPopup(message, handleOnClosedPopup) {
    this.props.openAlert(
      (handleClickPopup) => {
        return {
          maxWidth: "xs",
          title: DEFAULT_POPUP_TITLE,
          description: message,
          actions: [
            {
              label: "확인",
              onClick: handleClickPopup,
            },
          ],
        };
      },
      this.handleClickPopup,
      handleOnClosedPopup || null
    );
  }

  handleClickPopup(event, close) {
    if (!!close) close();
  }

  render() {
    const Component = this.props.component;
    if (!Component) return null;

    return (
      <>
        <Component
          onOpenPopup={this.handleOpenPopup}
          onOpenToast={this.handleOpenToast}
          onRequestAjax={this.handleOnRequestAjax}
        />
      </>
    );
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators({ openAlert, openSnackbar }, dispatch)
)(SignInContainer);
